<template>
  <form @submit.prevent class="formulario-container">
    <div class="formulario-linha linha-tripla">
      <div class="formulario-item">
        <label class="formulario-item-label">O que o usuário pode acessar?</label>
        <multiselect label="descricao" track-by="descricao" v-model="idTipoUsuario" :options="tiposUsuario"
          :searchable="false" :allow-empty="true" placeholder="Clique para selecionar" select-label="Selecionar"
          deselect-label="Remover" selected-label="Selecionado" />
      </div>
    </div>

    <transition name="fade-left" mode="out-in">
      <formulario-admin v-if="idTipoUsuario && idTipoUsuario.id === 2" :permissoes="perfilAcesso" />

      <formulario-participante v-if="idTipoUsuario && idTipoUsuario.id === 1 && showDataUser" :status="status"
        :cargos="cargos" :assistencias="selectAssistencias" :tipoAutorizadas="tipo" :franquias="selectFranquias" />
    </transition>

  </form>
</template>

<script>
import { buscaStatus, perfilAcesso } from "@/services/usuarios";
import { listarCargos } from "@/services/espelho";
import { listarAssistencias, listarTipoAutorizada } from "@/services/autorizadas";
import Multiselect from "vue-multiselect";
import FormularioAdmin from './usuarios/FormularioUsuarioAdmin.vue'
import FormularioParticipante from './usuarios/FormularioUsuarioParticipante.vue'

export default {
  components: {
    Multiselect,
    FormularioAdmin,
    FormularioParticipante
  },
  data() {
    return {
      cargos: [],
      status: [],
      tipo: [],
      selectAssistencias: [],
      selectFranquias: [],
      perfilAcesso: [],
      idTipoUsuario: [],
      tiposUsuario: [
        {
          id: 1,
          descricao: "Apenas site",
        },
        {
          id: 2,
          descricao: "Apenas área administrativa",
        }
      ],
      showDataUser: false
    };
  },

  watch: {
    idTipoUsuario: function (val) {
      this.isAdm(val) && !this.perfilAcesso.length && perfilAcesso().then((resp) => {
        this.perfilAcesso = resp.data;
      })
      !this.isAdm(val) && !this.cargos.length && Promise.all(this.reqUser()).finally(() => {
        this.showDataUser = true
      })
    },
  },

  methods: {
    isAdm(idUser) {
      return idUser && idUser.id === 2 && true
    },
    reqUser() {
      return [
        listarAssistencias(1).then((resp) => {
          this.selectAssistencias = resp.data;
        }),
        listarAssistencias(2).then((resp) => {
          this.selectFranquias = resp.data;
        }),
        listarCargos().then((resp) => {
          this.cargos = resp.data;
        }),
        buscaStatus().then((resp) => {
          this.status = resp.data;
        }),
        listarTipoAutorizada().then(resp => {
          this.tipo = resp.data.filter(tipo => tipo.id != 2 && tipo.id != 4)
        }),
      ]
    },
  },
};
</script>
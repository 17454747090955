import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";


export function perfilAcesso() {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().perfilAcesso, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function listarUsuario(filtroUsuarios, pag = 1) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    if (filtroUsuarios.cpf != null)
      filtroUsuarios.cpf = filtroUsuarios.cpf
        .replaceAll(".", "")
        .replaceAll("-", "");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(`${_rotasAPI().listarUsuario}?pagina=${pag}&quantidadePorPagina=${15}`, filtroUsuarios, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function detalhesUsuario(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().detalhesUsuario + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function detalhesUsuarioAdm(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().detalhesUsuarioAdm + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function detalhesUsuarioEditar(id, perfilUsuario) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().detalhesUsuarioEditar}${id}/${perfilUsuario}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function relatorioUsuario() {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().relatorioUsuario, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function buscaStatus() {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().buscaStatus, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function atualizarUsuario(dadosUsuario) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().atualizarUsuario, dadosUsuario, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: "sucesso",
        });
      })
      .catch((erro) => {
        tokenExpirado(erro, erro.response.data.mensagem);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function cadastrarUsuario(dadosUsuario) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().novoUsuarioParticipante, dadosUsuario, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          tipo: "sucesso",
          mensagem: resp.data.mensagem,
        });
      })
      .catch((erro) => {
        tokenExpirado(erro, erro.response.data.mensagem);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function cadastrarUsuarioAdmin(dadosUsuario) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().novoUsuarioAdmin, dadosUsuario, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          tipo: "sucesso",
          mensagem: resp.data.mensagem,
        });
      })
      .catch((erro) => {
        tokenExpirado(erro, erro.response.data.mensagem);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function pontuacaoUsuario(dados) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(_rotasAPI().pontuarUsuario, dados, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          tipo: "sucesso",
          mensagem: resp.data.mensagem,
        });
      })
      .catch((erro) => {
        tokenExpirado(erro, erro.response.data.mensagem);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}


export async function buscarOcorrenciaUsuario(id, pag, itensPag) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().buscarOcorrenciaUsuario}/${id}/${pag}/${itensPag}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function buscarHistoricoCargo(id, pag) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().historicoUsuarioCargo}/${id}/${pag}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function buscarHistoricoStatus(id, pag) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().historicoUsuarioStatus}/${id}/${pag}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export async function buscarHistoricoAutorizadas(id, pag) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().historicoUsuarioAutoriadas}/${id}/${pag}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}
<template>
    <form @submit.prevent="chamaConfirmacao" class="formulario-container">
        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="nome" class="formulario-item-label">Nome</label>
                <input type="text" v-model="usuario.nome" placeholder="Nome" name="nome"
                    :class="{ 'invalid-field': verificarSeHaItem('nome', this.errors, 'field') }" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('nome', this.errors, 'field')">
                    {{ verificarItemArray('nome', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="cpf" class="formulario-item-label">CPF</label>
                <input type="text" v-model="usuario.cpf" v-mask="'###.###.###-##'" placeholder="000.000.000-00" name="cpf"
                    :class="{ 'invalid-field': verificarSeHaItem('cpf', this.errors, 'field') }" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('cpf', this.errors, 'field')">
                    {{ verificarItemArray('cpf', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="dataNascimento" class="formulario-item-label">Data de Nascimento</label>
                <datepicker
                    :class="{ 'invalid-field-date-piker': verificarSeHaItem('dataNascimento', this.errors, 'field') }"
                    v-model="usuario.dataNascimento" placeholder="Data Nascimento" :language="ptBR" />
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('dataNascimento', this.errors, 'field')">
                    {{ verificarItemArray('dataNascimento', this.errors, 'field').message }}
                </p>
            </div>
        </div>

        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="email" class="formulario-item-label">Email</label>
                <input type="text" v-model="usuario.email" placeholder="Email" name="email"
                    :class="{ 'invalid-field': verificarSeHaItem('email', this.errors, 'field') }" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('email', this.errors, 'field')">
                    {{ verificarItemArray('email', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="celular" class="formulario-item-label">Celular</label>
                <input type="text" v-model="usuario.celular" placeholder="Celular" name="celular"
                    :class="{ 'invalid-field': verificarSeHaItem('celular', this.errors, 'field') }"
                    v-mask="'(##) #####-####'" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('celular', this.errors, 'field')">
                    {{ verificarItemArray('celular', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="cep" class="formulario-item-label">CEP</label>
                <input type="text" v-model="usuario.cep" placeholder="00000-000" name="cep" v-mask="'#####-###'"
                    @blur="buscaCep(usuario.cep, usuario)" />
            </div>

        </div>

        <div class="d-md-flex">

            <div class="formulario-item f-4">
                <label for="endereco" class="formulario-item-label">Endereço</label>
                <input type="text" v-model="usuario.endereco" name="endereco" placeholder="Endereço" />
            </div>

            <div class="formulario-item f-1">
                <label for="numero" class="formulario-item-label">Número</label>
                <input type="text" v-model="usuario.numero" placeholder="Número" name="numero" />
            </div>


            <div class="formulario-item">
                <label for="complemento" class="formulario-item-label">Complemento</label>
                <input type="text" v-model="usuario.complemento" placeholder="Complemento" name="complemento" />
            </div>
        </div>

        <div class="d-md-flex">
            <div class="formulario-item">
                <label for="bairro" class="formulario-item-label">Bairro</label>
                <input type="text" v-model="usuario.bairro" placeholder="Bairro" name="bairro" />
            </div>

            <div class="formulario-item">
                <label for="cidade" class="formulario-item-label">Cidade</label>
                <input type="text" v-model="usuario.cidade" placeholder="Cidade" name="cidade" />
            </div>

            <div class="formulario-item f-1">
                <label for="uf" class="formulario-item-label">UF</label>
                <multiselect v-model="usuario.uf" label="descricao" track-by="id" :options="regioesBr"
                    group-values="estados" group-label="descricao" :searchable="true" :multiple="false"
                    placeholder="Selecione" select-label="Selecionar" :allow-empty="false" deselect-label="Remover" selected-label="Selecionado">
                    <span slot="noResult">Item não encontrado</span>
                </multiselect>
            </div>

            <div class="formulario-item">
                <label for="pontoReferencia" class="formulario-item-label">Ponto Referência</label>
                <input type="text" v-model="usuario.pontoReferencia" placeholder="Ponto Referência"
                    name="pontoReferencia" />
            </div>
        </div>

        <div class="d-md-flex">
            <div class="formulario-item">
                <label class="formulario-item-label">Autorizada</label>
                <multiselect label="descricao" track-by="descricao" v-model="idSegmentacao" :options="tipoAutorizadas"
                    :searchable="false" :allow-empty="false" placeholder="Selecione" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" @input="removerMsgErro()"
                    :class="{ 'invalid-field-multiselect': verificarSeHaItem('idSegmentacao', this.errors, 'field') }" />
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('idSegmentacao', this.errors, 'field')">
                    {{ verificarItemArray('idSegmentacao', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="cargo" class="formulario-item-label">Cargo</label>
                <multiselect v-model="usuario.idCargo" label="descricao" track-by="id" :options="cargosFiltro"
                    :searchable="false" :multiple="false" placeholder="Cargo" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :allow-empty="true"
                    :class="{ 'invalid-field-multiselect': verificarSeHaItem('idCargo', this.errors, 'field') }"
                    @input="idAutorizada = []"
                    />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('idCargo', this.errors, 'field')">
                    {{ verificarItemArray('idCargo', this.errors, 'field').message }}
                </p>
            </div>
        </div>
        <div v-if="idSegmentacao && idSegmentacao.id" class="formulario-item linha-tripla">
                <label class="formulario-item-label">{{ verificarLabel() }}</label>
                <multiselect label="descricao" track-by="id" v-model="idAutorizada" :options="verificarArray()"
                    :searchable="true" :allow-empty="true" placeholder="Selecione" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :multiple="usuario.idCargo.id >= 8 ? true : false"
                    :max="verificaCargo()"
                    :class="{ 'invalid-field-multiselect': verificarSeHaItem('idAutorizada', this.errors, 'field') }">
                    <span slot="noResult">Item não encontrado</span>
                    <span slot="maxElements">
                        Só é possível adicionar 2 {{ verificarLabel() }}s
                    </span>
                </multiselect>
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('idAutorizada', this.errors, 'field')">
                    {{ verificarItemArray('idAutorizada', this.errors, 'field').message }}
                </p>
            </div>

        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="eenha" class="formulario-item-label">Senha</label>
                <div class="position-relative">
                    <input ref="senha1" type="password" v-model.trim="usuario.senha" placeholder="Senha" name="senha"
                        :class="{ 'invalid-field': verificarSeHaItem('senha', this.errors, 'field') }" />
                    <i class="icone-olho-senha" :class="{ active: senha1 }" @click="toggleMostrarSenha('senha1')"></i>
                </div>
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('senha', this.errors, 'field')">
                    {{ verificarItemArray('senha', this.errors, 'field').message }}
                </p>
                <p class="formulario-informacao">* A senha precisa conter no mínimo 8 dígitos, uma letra maiúscula,
                    uma letra minúscula, um número e um caractere especial
                    (ex.: @, !, ?, %)</p>
            </div>

            <div class="formulario-item">
                <label for="confirmarSenha" class="formulario-item-label">Confirmar senha</label>
                <div class="position-relative">
                    <input ref="senha2" type="password" v-model.trim="usuario.confirmarSenha" placeholder="Confirmar Senha"
                        name="confirmarSenha"
                        :class="{ 'invalid-field': verificarSeHaItem('confirmarSenha', this.errors, 'field') }" />
                    <i class="icone-olho-senha" :class="{ active: senha2 }" @click="toggleMostrarSenha('senha2')"></i>
                </div>
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('confirmarSenha', this.errors, 'field')">
                    {{ verificarItemArray('confirmarSenha', this.errors, 'field').message }}
                </p>
            </div>
        </div>

        <div class="container-btns">
            <button type="button" class="btn btn-cancelar" @click="voltar">Voltar</button>
            <button type="submit" class="btn btn-laranja">Salvar</button>
        </div>
    </form>
</template>
  
<script>
import { cadastrarUsuario } from "@/services/usuarios";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import { requests, functions, masks } from '@/assets/js'
import { brEstados } from '@/utils/json'

export default {
    props: ["cargos", "status", "assistencias", "tipoAutorizadas", "franquias"],
    components: {
        Datepicker,
        Multiselect,
    },
    data() {
        return {
            regioesBr: brEstados.ufRegiao,
            usuario: {
                senha: null,
                confirmarSenha: null,
                nome: '',
                cpf: '',
                dataNascimento: '',
                email: '',
                celular: '',
                idCargo: '',
                cep: '',
                endereco: '',
                numero: '',
                bairro: '',
                cidade: '',
                uf: '',
                complemento: '',
                pontoReferencia: '',
                status: ''
            },
            senha1: false,
            senha2: false,
            ptBR: ptBR,
            errors: [],
            idAutorizada: [],
            idSegmentacao: [],
            cargosFiltro: []
        };
    },
    watch: {
        idSegmentacao() {
            let idAssistencias = [1, 4, 7, 8, 9, 10];
            let idFranquias = [5, 6];
            if (this.idSegmentacao.id == 1) return this.cargosFiltro = this.cargos.filter(item => idAssistencias.find(i => i == item.id));
            if (this.idSegmentacao.id == 3) return this.cargosFiltro = this.cargos.filter(item => idFranquias.find(i => i == item.id));
        }
    },
    methods: {
        verificarSeHaItem: functions.verificarSeHaItem,
        verificarItemArray: functions.verificarItemArray,
        buscaCep: requests.buscaCep,
        verificaCargo() {
            if (this.usuario.idCargo.id >= 8 && this.usuario.idCargo.id <= 9) return 2;
            if (this.usuario.idCargo.id == 10) return 50;
        },
        verificarLabel() {
            return this.idSegmentacao.id == 1 ? "Assistência" : "Franquia"
        },
        verificarArray() {
            return this.idSegmentacao.id == 1 ? this.assistencias : this.franquias
        },
        voltar() {
            this.$router.push({ name: 'listar-usuarios' })
        },
        formData() {
            const data = new FormData();

            data.append("Nome", this.usuario.nome);
            data.append("Cpf", this.usuario.cpf.replace(/\D/g, ""));
            data.append("Email", this.usuario.email);
            data.append("Senha", this.usuario.senha);
            data.append("ConfirmarSenha", this.usuario.confirmarSenha);
            data.append("Celular", this.usuario.celular.replace(/\D/g, ""));
            data.append("DataNascimento", masks.formatarData(this.usuario.dataNascimento, "YYYY-MM-DD"));
            data.append("IdCargo", this.usuario.idCargo.id);
            // data.append("IdsAutorizadas", [this.idAutorizada.id]);

            if (this.usuario.idCargo && this.usuario.idCargo.id >= 8) {
                this.idAutorizada.forEach(({ id }) => {
                    data.append("IdsAutorizadas", id); // Apenas usuário site
                })
            }

            if (this.usuario.idCargo && this.usuario.idCargo.id < 8) {
                data.append("IdsAutorizadas", [this.idAutorizada.id]); // Apenas usuário site
            }

            data.append("CEP", this.usuario.cep.replace(/\D/g, ""));
            data.append("UF", this.usuario.uf.descricao == undefined ? '' : this.usuario.uf.descricao);
            data.append("Cidade", this.usuario.cidade);
            data.append("Bairro", this.usuario.bairro);
            data.append("Endereco", this.usuario.endereco);
            data.append("Numero", this.usuario.numero);
            data.append("Complemento", this.usuario.complemento);
            data.append("PontoReferencia", this.usuario.pontoReferencia);
            data.append("AceiteRegulamento", true);
            data.append("AceitePoliticaPrivacidade", true);
            data.append("AceiteReceberComunicacao", true);

            return data
        },
        cadastrarUsuario() {
            cadastrarUsuario(this.formData()).then(() => {
                this.voltar()
            });
        },
        chamaConfirmacao() {
            if (!this.formValid()) { // Caso tenha campo inválido ou não preenchido
                return;
            } else {
                // Modal
                const config = {
                    ativado: true,
                    tipo: "confirmacao",
                    mensagem: `Tem certeza que deseja cadastrar novo usuário?`,
                    func: this.cadastrarUsuario
                }
                this.$store.commit("SET_MODAL_MENSAGEM", config);
            }
        },
        formValid() {
            this.errors = [];

            if (this.usuario.nome == null || this.usuario.nome == "") {
                this.errors.push({ field: "nome", message: "O nome é obrigatório" });
            }

            if (this.usuario.email == null || this.usuario.email == "") {
                this.errors.push({ field: "email", message: "O email é obrigatório" });
            }

            if (this.usuario.dataNascimento == null || this.usuario.dataNascimento == "") {
                this.errors.push({ field: "dataNascimento", message: "A data de nascimento é obrigatória" });
            }

            const cpfSemCaracteres = this.usuario.cpf.replace(/\D/g, "")
            if (cpfSemCaracteres.length < 11) {
                this.errors.push({ field: "cpf", message: "CPF inválido" });
            }

            if (this.usuario.cpf == null || this.usuario.cpf == "") {
                this.errors.push({ field: "cpf", message: "O CPF é obrigatório" });
            }

            if (this.usuario.celular == null || this.usuario.celular == "") {
                this.errors.push({ field: "celular", message: "O celular é obrigatório" });
            }

            const celularSemCaracteres = this.usuario.celular.replace(/\D/g, "")
            if (celularSemCaracteres.length < 11) {
                this.errors.push({ field: "celular", message: "Celular inválido" });
            }

            if (this.usuario.idCargo == null || this.usuario.idCargo == "") {
                this.errors.push({ field: "idCargo", message: "Selecione o cargo" });
            }

            if (!functions.verificarDadoValido(this.idSegmentacao)) {
                this.errors.push({ field: "idSegmentacao", message: "Selecione o tipo da autorizada" });
            }

            if (functions.verificarDadoValido(this.idSegmentacao) && !functions.verificarDadoValido(this.idAutorizada)) {
                this.errors.push({ field: "idAutorizada", message: `Selecione a ${this.idSegmentacao.descricao}` });
            }

            if (this.usuario.senha == null || this.usuario.senha == "") {
                this.errors.push({ field: "senha", message: "Você precisa informar uma senha" });
            }

            if (this.usuario.confirmarSenha == null || this.usuario.confirmarSenha == "") {
                this.errors.push({ field: "confirmarSenha", message: "Você precisa confirmar a senha" });
            }

            if (this.errors.length > 0) {
                return false;
            }

            return true;
        },
        removerMsgErro() { // Esta função remove a mensagem de erro da autorizada quando a segmentação é removido
            this.idAutorizada = [];
            this.usuario.idCargo = [];
            const posicao = this.errors.map(el => el.field).indexOf('idAutorizada');
            posicao > -1 && this.errors.splice(posicao, 1);
        },
        toggleMostrarSenha(ref) {
            if (this.$refs[ref].type === "password" && (this.$refs[ref].name === "confirmarSenha" || this.$refs[ref].name === "senha")) {
                this[ref] = true;
                this.$refs[ref].type = "text";
            } else {
                this[ref] = false;
                this.$refs[ref].type = "password";
            }
        }
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
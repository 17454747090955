<template>
    <form @submit.prevent="chamaConfirmacao" class="formulario-container">
        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="nome" class="formulario-item-label">Nome</label>
                <input type="text" v-model="usuario.nome" placeholder="Nome" name="nome"
                    :class="{ 'invalid-field': verificarSeHaItem('nome', this.errors, 'field') }" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('nome', this.errors, 'field')">
                    {{ verificarItemArray('nome', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="cpf" class="formulario-item-label">CPF</label>
                <input type="text" v-model="usuario.cpf" v-mask="'###.###.###-##'" placeholder="000.000.000-00"
                    name="cpf" :class="{ 'invalid-field': verificarSeHaItem('cpf', this.errors, 'field') }" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('cpf', this.errors, 'field')">
                    {{ verificarItemArray('cpf', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="dataNascimento" class="formulario-item-label">Data de Nascimento</label>
                <datepicker v-model="usuario.dataNascimento" :language="ptBR"
                    :class="{ 'invalid-field-date-piker': verificarSeHaItem('dataNascimento', this.errors, 'field') }"
                    placeholder="Data Nascimento" />
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('dataNascimento', this.errors, 'field')">
                    {{ verificarItemArray('dataNascimento', this.errors, 'field').message }}
                </p>
            </div>
        </div>

        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="email" class="formulario-item-label">Email</label>
                <input type="text" v-model="usuario.email" placeholder="Email" name="email"
                    :class="{ 'invalid-field': verificarSeHaItem('email', this.errors, 'field') }" />
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('email', this.errors, 'field')">
                    {{ verificarItemArray('email', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="celular" class="formulario-item-label">Celular</label>
                <input type="text" v-model="usuario.celular" placeholder="Celular" name="celular"
                    :class="{ 'invalid-field': verificarSeHaItem('celular', this.errors, 'field') }"
                    v-mask="'(##) #####-####'" />
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('celular', this.errors, 'field')">
                    {{ verificarItemArray('celular', this.errors, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="cargo" class="formulario-item-label">Permissões</label>
                <multiselect v-model="permissaoAcesso" label="descricao" track-by="id" :options="permissoes"
                    :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :allow-empty="true"
                    :class="{ 'invalid-field-multiselect': verificarSeHaItem('permissaoAcesso', this.errors, 'field') }" />
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('permissaoAcesso', this.errors, 'field')">
                    {{ verificarItemArray('permissaoAcesso', this.errors, 'field').message }}
                </p>
            </div>
        </div>

        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="senha" class="formulario-item-label">Senha</label>
                <div class="position-relative">
                    <input ref="senha1" type="password" v-model.trim="usuario.senha" placeholder="Senha" name="senha"
                        :class="{ 'invalid-field': verificarSeHaItem('senha', this.errors, 'field') }" />
                    <i class="icone-olho-senha" :class="{ active: senha1 }" @click="toggleMostrarSenha('senha1')"></i>
                </div>
                <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('senha', this.errors, 'field')">
                    {{ verificarItemArray('senha', this.errors, 'field').message }}
                </p>
                <p class="formulario-informacao">* A senha precisa conter no mínimo 8 dígitos, uma letra maiúscula,
                    uma letra minúscula, um número e um caractere especial
                    (ex.: @, !, ?, %)</p>
            </div>

            <div class="formulario-item">
                <label for="confirmarSenha" class="formulario-item-label">Confirmar senha</label>
                <div class="position-relative">
                    <input name="confirmarSenha" ref="senha2" type="password" placeholder="Confirmar senha"
                        v-model.trim="usuario.confirmarSenha"
                        :class="{ 'invalid-field': verificarSeHaItem('confirmarSenha', this.errors, 'field') }" />
                    <i class="icone-olho-senha" :class="{ active: senha2 }" @click="toggleMostrarSenha('senha2')"></i>
                </div>
                <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('confirmarSenha', this.errors, 'field')">
                    {{ verificarItemArray('confirmarSenha', this.errors, 'field').message }}
                </p>
            </div>
        </div>

        <div class="container-btns margin-top">
            <button type="button" class="btn btn-cancelar" @click="voltar">Voltar</button>
            <button type="submit" class="btn btn-laranja">Salvar</button>
        </div>
    </form>
</template>
  
<script>
import { cadastrarUsuarioAdmin } from "@/services/usuarios";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import { functions, masks } from '@/assets/js'

export default {
    props: ["permissoes"],
    components: {
        Datepicker,
        Multiselect,
    },
    data() {
        return {
            id: 0,
            usuario: {
                nome: '',
                email: '',
                senha: '',
                confirmarSenha: '',
                dataNascimento: '',
                celular: '',
                cpf: ''
            },
            permissaoAcesso: [],
            ptBR: ptBR,
            errors: [],
            cargos: [],
            senha1: false,
            senha2: false
        };
    },
    methods: {
        verificarSeHaItem: functions.verificarSeHaItem,
        verificarItemArray: functions.verificarItemArray,
        voltar() {
            this.$router.push({ name: 'listar-usuarios' })
        },
        formData() {
            const data = new FormData();

            data.append("Nome", this.usuario.nome);
            data.append("CPF", this.usuario.cpf.replace(/[^\d]/g, ""));
            data.append("Email", this.usuario.email);
            data.append("Senha", this.usuario.senha);
            data.append("ConfirmarSenha", this.usuario.confirmarSenha);
            data.append("DataNascimento", masks.formatarData(this.usuario.dataNascimento, "YYYY-MM-DD"));
            data.append("IdPerfilAcessoPainel", this.permissaoAcesso.id);
            data.append("EmailExistente", '');
            data.append("Celular", this.usuario.celular.replace(/[^\d]/g, ""));
            data.append("IdStatus", '1');

            return data
        },
        cadastrarUsuario() {
            cadastrarUsuarioAdmin(this.formData()).then(() => {
                this.voltar()
            })
        },
        chamaConfirmacao() {
            if (!this.formValid()) { // Caso tenha campo inválido ou não preenchido
                return;
            } else {
                // Modal
                const config = {
                    ativado: true,
                    tipo: "confirmacao",
                    mensagem: `Tem certeza que deseja cadastrar novo usuário?`,
                    func: this.cadastrarUsuario
                }
                this.$store.commit("SET_MODAL_MENSAGEM", config);
            }
        },
        formValid() {
            this.errors = [];

            if (this.usuario.nome == null || this.usuario.nome == "") {
                this.errors.push({ field: "nome", message: "O nome é obrigatório" });
            }

            const cpfSemCaracteres = this.usuario.cpf.replace(/\D/g, "")
            if (cpfSemCaracteres.length > 0 && cpfSemCaracteres.length < 11) {
                this.errors.push({ field: "cpf", message: "CPF inválido" });
            }

            if (this.usuario.cpf == null || this.usuario.cpf == "") {
                this.errors.push({ field: "cpf", message: "O CPF é obrigatório" });
            }

            if (this.usuario.email == null || this.usuario.email == "") {
                this.errors.push({ field: "email", message: "O email é obrigatório" });
            }

            if (this.usuario.dataNascimento == null || this.usuario.dataNascimento == "") {
                this.errors.push({ field: "dataNascimento", message: "A data de nascimento é obrigatória", });
            }

            if (this.usuario.senha == null || this.usuario.senha == "") {
                this.errors.push({ field: "senha", message: "Você precisa informar uma senha" });
            }

            if (this.usuario.confirmarSenha == null || this.usuario.confirmarSenha == "") {
                this.errors.push({ field: "confirmarSenha", message: "Você precisa confirmar a senha" });
            }

            if (!functions.verificarDadoValido(this.permissaoAcesso)) {
                this.errors.push({ field: "permissaoAcesso", message: "Informe a permissão de acesso" });
            }

            const celularSemCaracteres = this.usuario.celular.replace(/\D/g, "")
            if (celularSemCaracteres.length > 0 && celularSemCaracteres.length < 11) {
                this.errors.push({ field: "celular", message: "Celular inválido" });
            }

            if (this.usuario.celular == '' || this.usuario.celular == null) {
                this.errors.push({ field: "celular", message: "O celular é obrigatório" });
            }

            if (this.errors.length > 0) {
                return false;
            }

            return true;
        },
        toggleMostrarSenha(ref) {
            if (this.$refs[ref].type === "password" && (this.$refs[ref].name === "confirmarSenha" || this.$refs[ref].name === "senha")) {
                this[ref] = true;
                this.$refs[ref].type = "text";
            } else {
                this[ref] = false;
                this.$refs[ref].type = "password";
            }
        }
    },
};
</script>